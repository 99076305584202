/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Button } from '../components';
import FullHeightColumn from '../components/FullHeightColumn';
import { Form, Field } from 'formik';
import RadioFieldGroup from '../components/RadioFieldGroup';
import RadioField from '../components/RadioField';
import { getCards } from '../state/session';
import { formatPrice } from '../utils/formatters';

const TimeForm = ({ values, isSubmitting, handleSubmit, price }) => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Form
      sx={{
        width: '100%',
        my: 2,
      }}
    >
      <RadioFieldGroup id="returnType">
        <Box
          sx={{
            border: '2px solid',
            borderColor: 'border',
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          <Box
            key={'return'}
            sx={{
              borderBottom: '1px solid',
              borderBottomColor: 'border',
              py: 1,
              px: 2,
              ':hover': {
                bg: 'blueLighter',
                cursor: 'pointer',
              },
            }}
          >
            <Field
              id="return"
              key={'return'}
              sx={{
                width: '100%',
              }}
              component={RadioField}
              name="returnType"
              label={
                <Flex
                  sx={{
                    alignSelf: 'center',
                    justifyContent: 'space-between',
                    flex: '1 !important',
                  }}
                >
                  <Box sx={{ flexShrink: '1 !important' }}>{translate('return.return')}</Box>
                  <Box sx={{ flex: 'none', pr: 2 }}>
                    <span sx={{ fontWeight: 'heading', color: 'primary' }}>{formatPrice(price)}</span>
                  </Box>
                </Flex>
              }
            />
          </Box>
          <Box
            key={'destroy'}
            sx={{
              borderBottom: '1px solid',
              borderBottomColor: 'border',
              py: 1,
              px: 2,
              ':hover': {
                bg: 'blueLighter',
                cursor: 'pointer',
              },
            }}
          >
            <Field
              id="destroy"
              key={'destroy'}
              sx={{
                width: '100%',
              }}
              component={RadioField}
              name="returnType"
              label={
                <Flex
                  sx={{
                    alignSelf: 'center',
                    justifyContent: 'space-between',
                    flex: '1 !important',
                  }}
                >
                  <Box sx={{ flexShrink: '1 !important' }}>{translate('return.recycle')}</Box>
                  <Box sx={{ flex: 'none', pr: 2 }}>
                    <span sx={{ fontWeight: 'heading', color: 'primary' }}>0.00 €</span>
                  </Box>
                </Flex>
              }
            />
          </Box>
        </Box>
      </RadioFieldGroup>

      <Flex
        sx={{
          justifyContent: ['flex-end', null, 'flex-start'],
          mt: 4,
        }}
      >
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {translate('return.continue')}
        </Button>
      </Flex>
    </Form>
  );
};

const returnTypeSchema = translate =>
  yup.object().shape({
    returnType: yup.string().required(translate('form.requiredField')),
  });

const ReturnTypeFormik = withFormik({
  mapPropsToValues: () => ({
    returnType: 'return',
  }),

  validationSchema: ({ translate }) => returnTypeSchema(translate),

  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },

  displayName: 'ReturnForm',
})(TimeForm);

export default ({ selectReturnType, state, price }) => {
  const { shipmentNumber } = state || {};
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));

  const onBackClick = useCallback(() => window.history.back(), []);

  const onSubmit = useCallback(
    values => {
      selectReturnType(values?.returnType);
    },
    [selectReturnType]
  );

  // preload user saved payment cards
  const user = useSelector(state => state.session.user);
  useEffect(() => {
    if (user && !user.cards) {
      dispatch(getCards());
    }
  }, [user, dispatch]);

  const formProps = {
    shipmentNumber,
    onSubmit,
    translate,
    price,
  };

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('return.title')}</Styled.h1>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <ReturnTypeFormik {...formProps} />
      </Flex>
    </FullHeightColumn>
  );
};
