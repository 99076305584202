/** @jsx jsx */
import { jsx, Box, Styled } from 'theme-ui';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Button, Link } from '../components';
import FullHeightColumn from '../components/FullHeightColumn';

export default ({ onRecycleConfirm, goBack }) => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={goBack} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('return.confirmTitle')}</Styled.h1>
      <p>
        {translate('return.recycleDescription')}{' '}
        <Link
          sx={{ textDecoration: 'underline' }}
          target="__blank"
          to={{ external: true, link: 'https://www.matkahuolto.fi/paketit/kuljetusehdot-kateisasiakkaille' }}
        >
          {translate('return.readMorefromTerms')}
        </Link>
      </p>
      <Box sx={{ mt: 3 }}>
        <Button onClick={onRecycleConfirm}>{translate('return.confirm')}</Button>
      </Box>
    </FullHeightColumn>
  );
};
