/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCards } from '../state/session';

import { PickupForm } from '../parcelFlow/PickupPoint';
import { useState } from 'react';
import * as api from '../utils/api';
import { useLanguage } from '../utils/getLanguage';
import { FullHeightColumn, Spinner } from '../components';

export default ({ shipment, selectPickupPoint, onGoBack, product }) => {
  const dispatch = useDispatch();
  const language = useLanguage();

  const [pickupPoint, setPickupPoint] = useState();
  useEffect(() => {
    if (pickupPoint || !shipment?.destinationPlaceId) {
      return;
    }

    (async () => {
      const response = await api.getServicePointById(shipment.destinationPlaceId, [], language);
      if (response[0]) {
        setPickupPoint(response[0]);
      }
    })();
  }, [pickupPoint, setPickupPoint, shipment, language]);

  const handleSubmit = useCallback(() => {
    selectPickupPoint(pickupPoint);
  }, [selectPickupPoint, pickupPoint]);

  // preload user saved payment cards
  const user = useSelector(state => state.session.user);
  useEffect(() => {
    if (user && !user.cards) {
      dispatch(getCards());
    }
  }, [user, dispatch]);

  const handleSelect = useCallback(v => {
    setPickupPoint(v);
  }, []);

  const props = {
    onBackClick: onGoBack,
    onNextClick: handleSubmit,
    onSelect: handleSelect,
    country: 'FI',
    pickupPoint: pickupPoint || {},
    title: 'return.choosePickupPoint',
    product,
  };

  if (!pickupPoint && shipment?.destinationPlaceId) {
    return (
      <FullHeightColumn>
        <div>
          <Spinner size="medium" position="fixed" />
        </div>
      </FullHeightColumn>
    );
  }

  return <PickupForm {...props} />;
};
