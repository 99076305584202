import { jsx } from 'theme-ui';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PaymentSelection } from '../components/Payment';
import { createOrder } from './order';

export default ({ shipmentNumber, goBack, returnType, pickupPoint }) => {
  const dispatch = useDispatch();
  const [isProcessing, setProcessing] = useState(false);

  const onPayButton = async paymentOptions => {
    if (isProcessing) {
      return;
    }
    try {
      setProcessing(true);
      await dispatch(createOrder(shipmentNumber, returnType, pickupPoint.officeCode, paymentOptions));
      setProcessing(false);
    } catch (error) {
      //
    }
  };

  return <PaymentSelection onPayButton={onPayButton} onBackClick={goBack} />;
};
