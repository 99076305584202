/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import FullHeightColumn from '../components/FullHeightColumn';
import { Link } from '../components';
import { reloadShipments } from '../state/shipments';

export default ({ shipment }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const user = useSelector(state => state.session.user);

  useEffect(() => {
    dispatch(reloadShipments());
  }, [dispatch]);

  return (
    <FullHeightColumn>
      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('return.title')}</Styled.h1>
      {shipment?.timeoutReturnType === 'RETURN' ? (
        <>
          <p>{translate('return.confirmReturn', { pickupPointDescription: shipment?.destinationPlaceName })}</p>
          {user ? <Link to={'/my-pages/incoming-parcels'}>{translate('delivery.agree.return')}</Link> : null}
        </>
      ) : (
        <>
          <p>{translate('return.confirmRecycle')}</p>
          {user ? <Link to={'/my-pages/incoming-parcels'}>{translate('delivery.agree.return')}</Link> : null}
        </>
      )}
    </FullHeightColumn>
  );
};
